<template>
  <b-form
    v-if="$can('all', 'customer')"
    ref="form"
    @submit.prevent="createCustomer"
  >
    <b-row>

      <!-- first name -->
      <b-col md="6">
        <b-form-group
          label="İsim"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              v-model="form.name"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- email -->
      <b-col md="6">
        <b-form-group
          label="Email"
          label-for="vi-email"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MailIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-email"
              v-model.trim="form.email"
              :disabled="editControl"
              required
              type="email"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- mobile -->
      <b-col md="6">
        <b-form-group
          label="Telefon"
          label-for="vi-mobile"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SmartphoneIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-mobile"
              v-model="form.phone"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- password -->
      <b-col
        v-if="!editControl"
        md="6"
      >
        <b-form-group
          label="Password"
          label-for="vi-password"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="LockIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-password"
              v-model="form.password"
              type="password"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Kısa isim"
          label-for="vi-short_name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-short_name"
              v-model="form.short_name"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Tip"
          label-for="vi-contact_type"
        >
          <b-form-select
            id="vi-contact_type"
            v-model="form.contact_type"
            :options="contact_types"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="tax_office"
          label-for="vi-tax_office"
        >
          <b-form-input
            id="vi-tax_office"
            v-model="form.tax_office"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="TC No"
          label-for="vi-tax_number"
        >
          <b-form-input
            id="vi-tax_number"
            v-model="form.tax_number"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="İlçe"
          label-for="vi-district"
        >
          <b-form-input
            id="vi-district"
            v-model="form.district"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="İl"
          label-for="vi-city"
        >
          <b-form-input
            id="vi-city"
            v-model="form.city"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Ülke"
          label-for="vi-country"
        >
          <b-form-input
            id="vi-country"
            v-model="form.country"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Adress"
          label-for="vi-address"
        >
          <b-form-input
            id="vi-address"
            v-model="form.address"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="fax"
          label-for="vi-fax"
        >
          <b-form-input
            id="vi-fax"
            v-model="form.fax"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="İban"
          label-for="vi-iban"
        >
          <b-form-input
            id="vi-iban"
            v-model="form.iban"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="İzlenemez"
          label-for="vi-untrackable"
        >
          <b-form-checkbox
            id="vi-untrackable"
            v-model="form.untrackable"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Yurt Dışı"
          label-for="vi-is_abroad"
        >
          <b-form-checkbox
            id="vi-is_abroad"
            v-model="form.is_abroad"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Arşiv"
          label-for="vi-archived"
        >
          <b-form-checkbox
            id="vi-archived"
            v-model="form.archived"
          />
        </b-form-group>
      </b-col>

      <!-- reset and submit -->
      <b-col md="6">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          {{ editControl ? 'Güncelle' : 'Kaydet' }}
        </b-button>
        <b-button
          v-if="!editControl"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        name: null,
        short_name: null,
        contact_type: null,
        tax_office: null,
        tax_number: null,
        district: null,
        city: null,
        country: null,
        address: null,
        phone: null,
        fax: null,
        is_abroad: false,
        archived: false,
        iban: null,
        account_type: 'customer',
        untrackable: false,
      },
      contact_types: [
        {
          text: 'Kişi',
          value: 'person',
        },
        {
          text: 'Firma',
          value: 'company',
        },
      ],
    }
  },
  computed: {
    editControl() {
      return !!this.$route.query.editId
    },
  },
  created() {
    this.$http.get(`parasut/customer/${this.$route.query.editId}`).then(res => {
      Object.assign(this.form, res.data.data.attributes)
    })
    if (!this.$can('all', 'customer')) {
      this.$router.push({ name: 'error-404' })
    }
  },
  methods: {
    ...mapActions('customer', {
      addCustomer: 'addCustomer',
    }),
    createCustomer() {
      if (!this.editControl) {
        this.addCustomer(this.form).then(res => {
          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$refs.form.reset()
          }
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lütfen formu kontrol edin',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        })
      } else {
        this.updateCustomer()
      }
    },
    updateCustomer() {
      this.$http.put(`parasut/customer/${this.$route.query.editId}`, this.form)
      // .then(res => {
      //   if (res.status === 200) {
      //     alert('başarılı')
      //   }
      // })
    },
  },
}
</script>
